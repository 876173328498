<template>
  <div>
    <div class="air__utils__heading"> <h5>Administración de Lotes</h5> </div>
    <my-breadcrumbs :routes="routes"/>
  </div>
</template>

<script>
export default {
  name: 'mainOperativeBatch',
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'operations',
          breadcrumbName: 'Operaciones',
        },
        {
          name: 'operation_process',
          breadcrumbName: 'Proceso Operativo',
        },
        {
          breadcrumbName: 'Administración de Lotes',
          aIcon: '',
        },
      ],
    }
  },
}
</script>
